import React from "react"
import { usePortfolio } from "../../../context/PortfolioContext"
import CategoryRow from "./CategoryRow"

const PortfolioTable = ({ holdings }) => {
  console.log(holdings)
  const { categories, mv } = usePortfolio()
  categories.forEach(
    c =>
      (c["mv"] = holdings
        .filter(h => h.asset.categoryName === c.name)
        .reduce((prev, curr) => prev + curr.units * curr.price.price, 0))
  )
  categories.sort((a, b) => b.mv - a.mv)
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadow border-b border-gray-200 my-8">
            <table className="w-full text-gray-100">
              <thead>
                <tr className="text-left border-b-2 lg:text-xl font-light rounded-t-lg overflow-hidden">
                  <td className="px-2 lg:px-4 py-3">Name</td>
                  <td className="px-2 lg:px-4 py-3">Allocation</td>
                </tr>
              </thead>
              <tbody>
                {categories.map(subtotal => {
                  const subHoldings = holdings
                    .filter(h => h.asset.categoryName === subtotal.name)
                    .sort(
                      (a, b) =>
                        b.units * b.price.price - a.units * a.price.price
                    )
                  return (
                    <CategoryRow
                      name={subtotal.name}
                      color={subtotal.color}
                      allocation="20%"
                      subHoldings={subHoldings}
                      mv={mv}
                      key={subtotal.id}
                    />
                  )
                })}
                <tr className="text-left border-b-2 border-gray-600">
                  <td className="px-2 lg:px-4 py-3">Total</td>
                  <td className="px-2 lg:px-4 py-3">100%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioTable
