import React from "react"
import HoldingRow from "./HoldingRow"

const CategoryRow = ({ name, color, mv, subHoldings }) => {
  const subMV = subHoldings.reduce(
    (prev, curr) => prev + curr.units * curr.price.price,
    0
  )

  return (
    <>
      <tr
        className={`bg-${color}-500 bg-opacity-50 text-left border-b-2 border-gray-600`}
      >
        <td className="px-2 lg:px-4 py-2">{name}</td>
        <td className="px-2 lg:px-4 py-2">{`${((subMV / mv) * 100).toFixed(
          1
        )} %`}</td>
      </tr>
      {subHoldings.map((h, index) => {
        return (
          <HoldingRow
            name={h.asset.name}
            allocation={`${(((h.units * h.price.price) / mv) * 100).toFixed(
              1
            )} %`}
            color={color}
            opacity={index % 2 === 0 ? 10 : 5}
          />
        )
      })}
    </>
  )
}

export default CategoryRow
