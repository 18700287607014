import React from "react"

const HoldingRow = ({ name, allocation, color, opacity }) => {
  return (
    <tr
      className={`bg-${color}-500 bg-opacity-${opacity} text-left border-b border-${color}-700`}
    >
      <td className="px-2 lg:px-4 py-2">{name}</td>
      <td className="px-2 lg:px-4 py-2">{allocation}</td>
    </tr>
  )
}

export default HoldingRow
